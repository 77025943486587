.RemoveButton {
    border-radius: 100%;
    height: 45px;
    width: 45px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.RemoveButton__image {
    height: 35px;
    width: 35px;
}

.RemoveButton:hover {
    cursor: pointer;
}

.RemoveButton--active {
    background-color: red;
}
.ListSection .Table {
    width: 100%;
    text-align: left;
}

.ListSection .PrintBtn {
    border: 2px solid dimgrey;
    display: inline-block;
    padding: 6px 10px;
    font-size: 19px;
    font-weight: bold;
    color: dimgrey;
    cursor: pointer;
    margin-left: 15px;
    position: relative;
    bottom: 3px;
}

.ListSection .PrintBtn:focus {
     outline:0;
 }
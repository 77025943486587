.ColorPicker .presets {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.ColorPicker .colorBlock {
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
}

.ColorPicker .block {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
    background-color: red;
    border: 1px solid black;
}

.ColorPicker .first-pick {
    background-color: #CB2821;
}

.ColorPicker .second-pick {
    background-color: #26252D;
}

.ColorPicker .third-pick {
    background-color: #8A9597;
}

.ColorPicker .fourth-pick {
    background-color: #323438;
}

.ralError {
    color: #5e5e5e;
}

.hidden {
    display: none;
}

.ColorPicker .label {
    font-size: 12px;
    font-weight: bold;
}

.ColorPicker .customInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.ColorPicker .Wrapper input {
    width: 85px;
    padding: 5px;
}

.ColorPicker .Wrapper label {
    color: black;
    font-size: 12px;
    font-weight: bold;
}

.ColorPicker .Link {
    color: black;
    text-decoration: underline;
    font-size: 12px;
    display: block;
    margin-top: 4px;
}
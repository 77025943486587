.App {
  max-width: 1000px;
  max-height: 1000px;
  margin: auto;
  padding: 25px;
}

.Loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
  color: white;
  margin-left: -100px;
  margin-top: -100px;
}

.App__header {
  display: flex;
  justify-content: space-between;
}

.App__header-link {
  font-size: 13px;
  align-self: center;
}

.App__header-link a {
  color: black;
}

.App__header-title {
  color: #5e5e5e;
  font-size: 25px;
  margin-left: 5px;
  margin-top: 0;
}

.App__header-title img {
  position: relative;
  top: 10px;
  margin-left: 10px;
}

.App__wrapper {
  position: relative;
}

.App__wrapper-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

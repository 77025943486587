.ModuleSection {
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.ModuleSection .SelectOptions {
    display: flex;
    justify-content: space-between;
    width: 680px;
}

.ModuleSection .SelectOptions .Container {
    width: 105px;
    box-sizing: border-box;
}

.ModuleSection .SelectOptions .Container img {
    border: 1px solid black;
    width: 100%;
    height: 80px;
    box-sizing: border-box;
}

.ModuleSection .SelectOptions .Container .selected {
    border: solid 4px black;
}

.ModuleSection .SelectOptions .Container p {
    margin: 0;
    font-size: 13px;
    text-align: center;
}



@media only screen and (max-width: 800px) {
  .ModuleSection {
    display: block;
  }
}